import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Params, RouterModule } from '@angular/router';
import { IntlModule } from '@rcg/intl';
import { IPaging } from '../../../models/paging.model';
import { ModuleSearchComponent } from '../../module-search/module-search.component';
import { ListDynamicStyle } from '../dynamic-style.decorator';

@ListDynamicStyle
@Component({
  selector: 'rcg-list',
  standalone: true,
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, MatProgressBarModule, MatCardModule, ModuleSearchComponent, IntlModule],
})
export class RcgListComponent<Item extends { id?: string | number } = { id?: string | number }> {
  @Input() itemTemplate?: TemplateRef<unknown>;

  @Input() listHeader?: TemplateRef<unknown> | null | undefined;

  @Input() data: Item[] = [];

  @Input() paging!: IPaging;

  @Input() loading = false;

  @Input() error: string | null | undefined = null;

  @Input() queryParams?: Params;

  @Input() itemSize?: number = 80;

  @Input() itemWidth?: string = '400px';

  @Input() wrapInCard = true;

  @Input() searchable = false;

  @Input() route?: string;

  @Output() search = new EventEmitter<string>();

  @Output() loadMore = new EventEmitter<IPaging>();

  constructor(public hostElRef: ElementRef) {}

  searchChanged(search: string) {
    this.search.emit(search);
  }
}
