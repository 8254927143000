<rcg-module-search
  *ngIf="searchable"
  style="padding-left: 4px"
  [search_CAPTION]="'placeholder_search' | intl"
  (searchPhrase)="searchChanged($event)"
>
</rcg-module-search>

<ng-container *ngIf="loading">
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
</ng-container>

<ng-container *ngIf="!error && !loading && data.length === 0">
  <div class="center">
    <div>{{ 'no_data' | intl }}</div>
  </div>
</ng-container>

<ng-container *ngIf="error">
  <div class="center error">
    <div>{{ error }}</div>
  </div>
</ng-container>

<div *ngIf="listHeader" class="list-header">
  <ng-container [ngTemplateOutlet]="listHeader!" [ngTemplateOutletContext]="{ data: data }"> </ng-container>
</div>

<div class="list-element">
  <!-- with card -->
  <ng-container *ngIf="wrapInCard">
    <mat-card
      *ngFor="let item of data"
      appearance="outlined"
      class="list-item"
      [ngStyle]="{ height: itemSize + 'px' }"
      [routerLink]="route ? route + '/' + item?.id : queryParams ? item?.id + '' : null"
      [queryParams]="queryParams"
      routerLinkActive="rcg-active-route"
    >
      <ng-container *ngIf="itemTemplate" [ngTemplateOutlet]="itemTemplate!" [ngTemplateOutletContext]="{ data: item }"></ng-container>

      <ng-container *ngIf="!itemTemplate">
        <div>No template data</div>
      </ng-container>
    </mat-card>
  </ng-container>

  <!-- No card -->
  <ng-container *ngIf="!wrapInCard">
    <div
      *ngFor="let item of data"
      [routerLink]="route ? route + '/' + item?.id : queryParams ? item?.id + '' : null"
      routerLinkActive="rcg-active-route"
    >
      <ng-container *ngIf="itemTemplate" [ngTemplateOutlet]="itemTemplate!" [ngTemplateOutletContext]="{ data: item }"></ng-container>

      <ng-container *ngIf="!itemTemplate">
        <div>No template data</div>
      </ng-container>
    </div>
  </ng-container>
</div>
