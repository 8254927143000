import { Pipe, PipeTransform } from '@angular/core';

//? https://en.wikipedia.org/wiki/Metric_prefix
const suffixes = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y', 'R', 'Q'];

@Pipe({
  name: 'metricNumber',
  standalone: true,
  pure: true,
})
export class MetricNumberPipe implements PipeTransform {
  transform(val: number | null | undefined, fraction?: number, hideZeroFraction = false) {
    if (!val) return val;

    if (Number.isNaN(val)) return 'NaN';
    if (val < 1000) return val;

    const exp = Math.floor(Math.log(val) / Math.log(1000));
    if (exp >= 10) return '∞';

    let fracNum = (val / Math.pow(1000, exp)).toFixed(fraction);
    if (hideZeroFraction && fracNum.endsWith('.0')) fracNum = fracNum.slice(0, -2);
    return fracNum + suffixes[exp - 1];
  }
}
