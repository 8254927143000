<mat-icon class="rcg-dialog-minimized-icon">{{ icon }}</mat-icon>
<span class="rcg-dialog-minimized-icon-tooltip">{{ title }}</span>

<mat-toolbar color="primary" rcgDialogDragHandle>
  <div class="header">
    <mat-icon [matTooltip]="iconTooltip ?? ''" class="dialog-icon">{{ icon }}</mat-icon>
    <div class="title">{{ title }}</div>
  </div>

  <div class="toolbar-icons">
    <ng-content select="toolbar-icons"></ng-content>

    <mat-icon
      *ngIf="!disabledToolbarActions.includes('recenter')"
      [matTooltip]="'recenter' | intl"
      matTooltipPosition="below"
      class="rcg-dialog-recenter-icon"
      >adjust</mat-icon
    >
    <mat-icon
      *ngIf="!disabledToolbarActions.includes('minimize')"
      [matTooltip]="'minimize_window' | intl"
      matTooltipPosition="below"
      (click)="minimizeDialog($event.target)"
      >minimize</mat-icon
    >
    <mat-icon
      *ngIf="!disabledToolbarActions.includes('fullscreen')"
      [matTooltip]="'fullscreen_window' | intl"
      matTooltipPosition="below"
      (click)="fullscreenDialog($event.target)"
      >fullscreen</mat-icon
    >
    <mat-icon
      *ngIf="!disabledToolbarActions.includes('close')"
      [matTooltip]="'close_window' | intl"
      matTooltipPosition="below"
      class="close-icon"
      (click)="dialogRef.close()"
      >close</mat-icon
    >
  </div>
</mat-toolbar>

<mat-dialog-content>
  <ng-content></ng-content>
  <div *ngIf="dialogActions.children.length" style="flex-grow: 1"></div>
  <div #dialogActions style="width: 100%"><ng-content select="mat-dialog-actions"></ng-content></div>
</mat-dialog-content>
