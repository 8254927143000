<rcg-module-search
  *ngIf="searchable"
  style="padding-left: 4px"
  [search_CAPTION]="'placeholder_search' | intl"
  (searchPhrase)="searchChanged($event)"
>
</rcg-module-search>

<ng-container *ngIf="listHeader" [ngTemplateOutlet]="listHeader!" [ngTemplateOutletContext]="{ data: data }"> </ng-container>

<cdk-virtual-scroll-viewport
  class="list-element"
  [ngStyle]="{
    width: itemWidth,
    'max-width': itemWidth,
    'min-width': itemWidth
  }"
  [itemSize]="itemSize"
  (scrolledIndexChange)="scrollIndexChanged($event, paging, loading)"
>
  <ng-container *ngIf="loading">
    <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
  </ng-container>

  <ng-container *ngIf="!error && !loading && data.length === 0">
    <div class="center">
      <div>{{ 'no_data' | intl }}</div>
    </div>
  </ng-container>

  <ng-container *ngIf="error">
    <div class="center error">
      <div>{{ error }}</div>
    </div>
  </ng-container>

  <!-- with card -->
  <ng-container *ngIf="wrapInCard">
    <mat-card
      appearance="outlined"
      *cdkVirtualFor="let item of data; let even = even; let odd = odd; let index = index"
      class="list-item"
      [ngStyle]="{ height: itemSize + 'px', padding: padding ? padding : null, margin: margin ? margin : null }"
      [routerLink]="route ? route + '/' + item?.id : queryParams ? item?.id : null"
      [queryParams]="queryParams"
      routerLinkActive="rcg-active-route"
    >
      <ng-container *ngIf="itemTemplate" [ngTemplateOutlet]="itemTemplate!" [ngTemplateOutletContext]="{ data: item }"></ng-container>

      <ng-container *ngIf="!itemTemplate">
        <div>No template data</div>
      </ng-container>
    </mat-card>
  </ng-container>

  <!-- no card -->
  <ng-container *ngIf="!wrapInCard">
    <div
      *cdkVirtualFor="
        let item of data;
        let even = even;
        let odd = odd;
        let first = first;
        let last = last;
        let index = index;
        trackBy: trackBy
      "
      class="list-item"
      [ngStyle]="{ height: itemSize + 'px', padding: padding ? padding : null, margin: margin ? margin : null }"
    >
      <ng-container
        *ngIf="itemTemplate"
        [ngTemplateOutlet]="itemTemplate!"
        [ngTemplateOutletContext]="{ data: item, even, odd, first, last, index }"
      ></ng-container>

      <ng-container *ngIf="!itemTemplate">
        <div>No template data</div>
      </ng-container>
    </div>
  </ng-container>
</cdk-virtual-scroll-viewport>
