<rcg-centered-card>
  <div ngProjectAs="title" class="card-title">
    @if (authInfo() && (authenticatableMfaFactors()?.length ?? 0) > 1) {
    <button class="back-button" mat-icon-button color="primary" (click)="clearFactor()"><mat-icon>arrow_back</mat-icon></button>
    }

    <span>{{ 'multifactor_authentication' | intl }}</span>
  </div>

  @if (authInfo(); as info) {
  <div class="auth-info-container">
    <h3 class="title">{{ titleTranslations[info.type] ?? '???' | intl }}</h3>

    @switch (info.type) { @case ('OTP/RFC6238') {
    <rcg-mfa-totp-authenticator class="authenticator" [numDigits]="numDigits" (done)="done($event)"></rcg-mfa-totp-authenticator>
    } @case ('OTP/SMS') {
    <rcg-mfa-sms-authenticator class="authenticator" [numDigits]="numDigits" (done)="done($event)"></rcg-mfa-sms-authenticator>
    } @case ('recovery/code') {
    <rcg-mfa-recovery-code-authenticator class="authenticator" (done)="done($event)"></rcg-mfa-recovery-code-authenticator>
    } @default {
    <span class="error-text">{{ 'mfa_error_invalid_auth_info' | intl }}</span>
    } }
  </div>
  } @else { @if (authenticatableMfaFactors()?.length) {
  <div class="authenticatable-factors-container">
    @for (amf of authenticatableMfaFactors(); track $index) {
    <mat-card class="authenticatable-factor" (click)="pickFactor(amf)">
      @switch (amf.type) { @case ('OTP/RFC6238') {
      <mat-icon color="primary" [matBadge]="amf.digits">update</mat-icon>
      <span>{{ 'mfa_type_totp' | intl }}</span>
      } @case ('OTP/SMS') {
      <mat-icon color="primary" [matBadge]="amf.digits">textsms</mat-icon>
      <span>{{ 'mfa_type_sms' | intl }}</span>
      } @case ('recovery/code') {
      <mat-icon color="primary">sync_problem</mat-icon>
      <span>{{ 'mfa_type_recovery_code' | intl }}</span>
      } @default {
      <span class="error-text">???</span>
      } }
    </mat-card>
    }
  </div>
  } @else { @if (presentMfaFactors()?.length) {
  <span class="error-text">{{ 'mfa_error_no_authenticatable_mfa_factors' | intl }}</span>
  } @else {
  <div class="authentication-factor-add-container">
    <span>{{ 'mfa_error_no_authenticatable_mfa_factors' | intl }}</span>

    <formly-form [fields]="addAuthFactorFormFields"></formly-form>
  </div>
  } } }
</rcg-centered-card>
