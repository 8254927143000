export interface MutexOptions {
  onLockChanged?: (locked: boolean) => void | Promise<void>;
}

export class Mutex {
  private readonly _waiting: (() => void)[] = [];

  private locked = false;

  constructor(private _options?: MutexOptions) {}

  private async _notifyLockChange() {
    try {
      await this._options?.onLockChanged?.(this.locked)?.catch(() => null);
    } catch (error) {
      console.warn('Mutex lock change notify error', error);
    }
  }

  public async protect<T>(fn: () => Promise<T>): Promise<T> {
    if (this.locked) {
      await new Promise<void>((res) => this._waiting.push(res));
    }

    //! This should never happen as the lock is always released before the waiting promise is resolved, added as an extra precaution
    if (this.locked) throw new Error('Mutex lock error: tried to lock more than once (wtf??)');

    try {
      this.locked = true;
      await this._notifyLockChange();
      return await fn();
    } finally {
      this.locked = false;
      await this._notifyLockChange();
      this._waiting.shift()?.();
    }
  }
}
