<div class="input-container">
  <mat-form-field>
    <input matInput #input [disabled]="processing()" />
  </mat-form-field>

  <button mat-icon-button (click)="codeEntered(input.value)">
    <mat-icon color="primary">send</mat-icon>
  </button>
</div>

<mat-spinner *ngIf="processing()" mode="indeterminate" [diameter]="48"></mat-spinner>
