import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';
import { randomClassName } from '../../utils/dom-utils';

function mapProp<P extends string>(propName: P): `__ds_${P}` {
  return `__ds_${propName}`;
}

const propList = ['uniqueClassName', 'el', 'styleEl', 'hasInit', 'init', 'update'] as const;

const prop = propList.map((p) => ({ [p]: mapProp(p) })).reduce((a, b) => ({ ...a, ...b })) as {
  [x in (typeof propList)[number]]: ReturnType<typeof mapProp<x>>;
};

interface HostElRef {
  hostElRef: ElementRef;
}

interface Props {
  [prop.uniqueClassName]: string;
  [prop.el]: HTMLElement;
  [prop.styleEl]: HTMLStyleElement;
  [prop.hasInit]: boolean;
  [prop.init]: typeof init;
  [prop.update]: typeof update;
}

type DynamicListComponent = Component & AfterViewInit & OnDestroy & HostElRef & Props;

function init(this: DynamicListComponent) {
  const resObserver = new ResizeObserver(() => {
    setTimeout(() => {
      this[prop.update]();
    });
  });
  resObserver.observe(this[prop.el]);

  this[prop.hasInit] = true;
  this[prop.update]();
}

function update(this: DynamicListComponent) {
  if (!this[prop.hasInit]) {
    return this[prop.init]();
  }

  const el = this[prop.el];

  const childElements = el.querySelectorAll(':scope > *:not(.list-element, .center)');
  const childrenHeight = [...childElements].map((e) => e.getBoundingClientRect().height).reduce((a, b) => a + b, 0);

  const sheet = new CSSStyleSheet();

  sheet.insertRule(`.${this[prop.uniqueClassName]} .center { height: ${el.getBoundingClientRect().height - childrenHeight}px }`);

  this[prop.styleEl].innerText = [...sheet.cssRules].map((r) => r.cssText).join('\n');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ListDynamicStyle<T extends new (...arg: any[]) => any>(target: T) {
  const targetProto = target.prototype as DynamicListComponent;
  const targetNgAfterViewInit = targetProto.ngAfterViewInit;

  targetProto[prop.init] = init;
  targetProto[prop.update] = update;

  targetProto.ngAfterViewInit = function (this: DynamicListComponent) {
    let error;

    try {
      targetNgAfterViewInit?.call(this);
    } catch (e) {
      error = e;
    }

    this[prop.uniqueClassName] = randomClassName();

    const el = this.hostElRef.nativeElement as HTMLElement;
    el.classList.add(this[prop.uniqueClassName]);
    const style = el.appendChild(document.createElement('style'));

    this[prop.el] = el;
    this[prop.styleEl] = style;

    const visObserver = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            this[prop.update]();
          }
        }
      },
      {
        root: document.documentElement,
      },
    );

    visObserver.observe(el);

    if (error) throw error;
  };

  return target;
}
