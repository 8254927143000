<mat-card appearance="outlined">
  @if(!hideTitle) {
  <mat-card-header>
    <mat-card-title><ng-content select="title"></ng-content></mat-card-title>
  </mat-card-header>
  }

  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
