import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MetricNumberPipe } from '../../pipes/metric-number.pipe';

@Component({
  selector: 'rcg-module-search',
  templateUrl: './module-search.component.html',
  styleUrls: ['./module-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
    MatInputModule,
    MetricNumberPipe,
  ],
})
export class ModuleSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() searchPhrase = new EventEmitter<string>();

  private _disabled = false;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;

    this._toggleComponentAvailability(value);
  }
  get disabled() {
    return this._disabled;
  }

  @Input() search_CAPTION = '';
  @Input() searchTooltip_CAPTION = '';

  @Input() debounceTime = 500;

  @Input()
  set searchInitialValue(value: string) {
    this._searchInitialValue = value;

    if (!this.searchForm) {
      this._createForm();
    }
    this._setSearchInitialValue(value);
  }
  get searchInitialValue() {
    return this._searchInitialValue;
  }

  @Input()
  set setSearchValue(value: string) {
    if (!this.searchForm) {
      this._createForm();
    }
    if (!this.searchForm?.get('search')) {
      this._outsideSearch = value;
      return;
    }
    this.searchForm!.get('search')!.setValue(value);
  }
  get setSearchValue() {
    return this.setSearchValue;
  }

  @Input() count?: number;

  searchForm!: UntypedFormGroup;

  private _searchInitialValue = '';

  private _initalValueSet = false;

  private _subscriptions: Subscription[] = [];
  private _outsideSearch = '';

  public isSearching = false;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    if (!this.searchForm) {
      this.searchForm = this._createForm();
    }

    const searchFormValueSubscription = this.searchForm.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe({
      next: () => {
        const val = this.searchForm!.get('search')!.value;
        this.searchPhrase.emit(val);
        this.isSearching = val?.length;
      },
      error: (error) => {
        console.error('Error occured in SearchComponent searchForm.valueChanges stream', error);
      },
    });

    this._subscriptions.push(searchFormValueSubscription);
  }

  ngAfterViewInit(): void {
    if (this._outsideSearch && this.searchForm?.get('search')) {
      this.searchForm!.get('search')!.setValue(this._outsideSearch);
    }
  }

  ngOnDestroy() {
    if (this._subscriptions && this._subscriptions.length > 0) {
      this._subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }

  clearSearchClicked(): void {
    if (this.disabled) {
      return;
    }
    this.searchForm!.get('search')!.setValue('');
  }

  searchClicked(search: string): void {
    if (this.disabled) {
      return;
    }
    if (search?.length > 0) {
      this.searchPhrase.emit(search);
    }
  }

  private _createForm(): UntypedFormGroup {
    return this._formBuilder.group({
      search: new UntypedFormControl(),
    });
  }

  private _setSearchInitialValue(value: string): void {
    if (this._initalValueSet === false) {
      this.searchForm!.get('search')!.setValue(value);

      this._initalValueSet = true;
    }
  }

  private _toggleComponentAvailability(disabled: boolean): void {
    if (!this.searchForm) {
      this._createForm();
    }

    if (disabled) {
      this.searchForm!.get('search')!.disable();
    }

    if (!disabled) {
      this.searchForm!.get('search')!.enable();
    }
  }
}
