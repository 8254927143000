import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, firstValueFrom } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogData } from '../components';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  async confirmDialogAsync(data: ConfirmDialogData): Promise<boolean | null> {
    const observable: Observable<boolean> = this.dialog
      .open(ConfirmDialogComponent, {
        data,
        width: data.width ?? '350px',
        height: data.height ?? '200px',
        disableClose: data.disableClose === undefined ? true : data.disableClose,
      })
      .afterClosed();
    return await firstValueFrom(observable);
  }

  showErrorSnackbar(message: string, detailMessage?: unknown, durationInSeconds = 3, action = 'Zapri', className?: string) {
    this.snackBar.open(this.createMessage(message, detailMessage), action, {
      duration: durationInSeconds * 1000,
      panelClass: className ?? ['rcg-error-snackbar'],
    });
  }

  showWarningSnackbar(message: string, detailMessage?: unknown, action = 'Zapri', durationInSeconds = 3, className?: string) {
    this.snackBar.open(this.createMessage(message, detailMessage), action, {
      duration: durationInSeconds * 1000,
      panelClass: className ?? ['rcg-warning-snackbar'],
    });
  }

  showInfoSnackbar(message: string, detailMessage?: unknown, action = 'Zapri', durationInSeconds = 3, className?: string) {
    this.snackBar.open(this.createMessage(message, detailMessage), action, {
      duration: durationInSeconds * 1000,
      panelClass: className ?? ['rcg-info-snackbar'],
    });
  }

  private createMessage(message: string, detailMessage?: unknown) {
    message = message ?? '';
    let parsedDetailMessage = '';
    if (detailMessage) {
      if (typeof detailMessage === 'string') {
        parsedDetailMessage = detailMessage;
      } else if (detailMessage instanceof Error) {
        parsedDetailMessage = detailMessage?.message ?? '';
      } else {
        parsedDetailMessage = detailMessage.toString();

        if (parsedDetailMessage === '[object Object]') {
          try {
            parsedDetailMessage = JSON.stringify(detailMessage);
          } catch (error) {
            //ignore
          }
        }
      }
    }

    return message + ' ' + parsedDetailMessage;
  }
}
