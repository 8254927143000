import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, OnInit, signal, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IntlModule } from '@rcg/intl';
import { RcgDirectivesModule } from '@rcg/standalone/directives';
import { isNonNullable } from '@rcg/utils/type.utils';
import { AuthService } from '../../../auth.service';
import { RcgTenant } from '../../../models';

@Component({
  selector: 'rcg-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    IntlModule,
    RcgDirectivesModule,
  ],
})
export class TenantComponent implements OnInit {
  @ViewChild('list', { read: ElementRef }) list?: ElementRef<HTMLElement>;
  @ViewChild('pickedItem', { read: ElementRef })
  pickedItem?: ElementRef<HTMLElement>;
  @ViewChild('in') input?: ElementRef<HTMLInputElement>;

  public readonly tenants = computed(() => {
    const user = this.auth.user();
    if (!user) return [];

    const last = user.tenants.find((t) => t.id === user.lastSelectedTenantId);

    return (
      [
        last
          ? {
              ...last,
              lastSelected: true,
            }
          : undefined,
        ...(user.tenants.filter((t) => t.id !== user.lastSelectedTenantId) ?? []),
      ] as ((RcgTenant | { id: number; description: string }) & { lastSelected?: true })[]
    ).filter(isNonNullable);
  });

  private readonly tenantSearchText = signal('');

  public readonly searchedTenants = computed(() => {
    const search = this.tenantSearchText();
    const tenants = this.tenants();

    const lowercaseSearch = search.toLowerCase();

    return (
      tenants?.filter((t) =>
        [`${t.id}`, t.description, 'organization' in t ? t.organization.name : undefined]
          .map((e) => e?.toLowerCase())
          .find((e) => e?.includes(lowercaseSearch)),
      ) ?? []
    );
  });

  constructor(public auth: AuthService) {
    effect(() => {
      this.tenantSearchText();
      setTimeout(() => this.list?.nativeElement?.scrollTo({ top: 0 }));
    });
  }

  ngOnInit() {
    setTimeout(() => this.input?.nativeElement.focus()); // setTimeout da dela floating label
  }

  searchKeyDown(event: KeyboardEvent) {
    if (['Tab', 'ArrowDown'].includes(event.key)) {
      if (event.shiftKey) return;

      const list = this.list?.nativeElement;
      const item = list?.querySelector<HTMLElement>('mat-list-item') ?? null;

      if (!item) {
        console.warn('Could not find item to focus!', { list, item });
        return;
      }

      setTimeout(() => item?.focus(), 10);
    }
  }

  searchKeyUp(event: KeyboardEvent, value: string) {
    this.tenantSearchText.set(value);

    if (event.key === 'Enter') {
      const list = this.list?.nativeElement;
      const item = list?.querySelector<HTMLElement>('mat-list-item') ?? null;

      if (!item) {
        console.warn('Could not find item to select!', { list, item });
        return;
      }

      const tenants = this.searchedTenants();

      if (tenants.length === 1) {
        item.click();
        return;
      }

      item.focus();
      return;
    }
  }

  listItemKeyDown(event: KeyboardEvent) {
    const item = (event.target as HTMLElement).closest<HTMLElement>('mat-list-item');

    if (!item) {
      console.warn('Could not find list item to focus with arrow keys!', { event, item });
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const children = Array.from(item.closest('.tenant-list')!.children).filter((c) => c.tagName === 'MAT-LIST-ITEM') as HTMLElement[];
    const index = children.indexOf(item);

    if (event.key === 'ArrowUp' || (event.key === 'Tab' && event.shiftKey)) {
      if (index === 0) {
        this.input?.nativeElement.focus();
        return;
      }

      children[index - 1].focus();
      return;
    }

    if (event.key === 'ArrowDown' || (event.key === 'Tab' && !event.shiftKey)) {
      if (index === children.length - 1) {
        return;
      }

      children[index + 1].focus();
      return;
    }

    if (event.key.length === 1) {
      const input = this.input?.nativeElement;
      if (!input) return;

      input.value += event.key;
      input.focus();

      return;
    }

    if (['Backspace', 'Escape'].includes(event.key)) {
      this.input?.nativeElement?.focus();
      return;
    }
  }

  listItemKeyUp(event: KeyboardEvent, tenant: RcgTenant) {
    if (event.key === 'Enter') this.auth.changeTenant(tenant.id);
  }

  preventTab(event: KeyboardEvent, back = true, forward = true) {
    if (event.key !== 'Tab') return;

    if (event.shiftKey) {
      if (back) event.preventDefault();
      return;
    }

    if (forward) event.preventDefault();
  }
}
