import { Directive, Input, TemplateRef, inject } from "@angular/core";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'ng-template[id]',
    standalone: true
})
export class TemplateIdDirective {
    @Input() id!: string;
    tpl = inject(TemplateRef);
}