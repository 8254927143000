<div class="selector-container">
  <h2 class="picker-header">{{ 'select_a_tenant' | intl }}</h2>

  <mat-card *ngVar="(tenants()?.length ?? 0) >= 10 as searchable">
    @if (searchable) {
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>{{ 'placeholder_search' | intl }}</mat-label>
      <input #in matInput autofocus (keydown)="searchKeyDown($event)" (keyup)="searchKeyUp($event, in.value)" />
    </mat-form-field>
    }

    <mat-nav-list #list class="tenant-list" [class.searchable]="searchable" *ngVar="this.searchedTenants() as searchedTenants">
      @for (tenant of searchedTenants; track $index) {
      <ng-container *ngTemplateOutlet="tenantItem; context: { tenant }"></ng-container>

      @if (tenant.lastSelected && searchedTenants.length > 1) {
      <mat-divider></mat-divider>
      } } @if (!searchedTenants.length) {
      <span class="no-tenant">{{ 'no_tenants_available' | intl }}</span>
      }
    </mat-nav-list>
  </mat-card>
</div>

<ng-template #tenantItem let-tenant="tenant">
  <mat-list-item
    tabindex="0"
    [activated]="!!tenant.lastSelected"
    (click)="auth.changeTenant(tenant.id)"
    (keydown)="listItemKeyDown($event)"
    (keyup)="listItemKeyUp($event, tenant)"
    *ngVar="tenant.organization?.name ?? tenant.orgName as orgName"
  >
    <mat-icon matListItemIcon>corporate_fare</mat-icon>
    <h3 matListItemTitle [style.margin-bottom]="orgName ? '' : '0'">{{ tenant.description }}</h3>
    @if (orgName) {
    <p matListItemLine>{{ orgName }}</p>
    }
  </mat-list-item>
</ng-template>
