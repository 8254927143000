import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MessageService } from '@rcg/standalone/services';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'rcg-mfa-recovery-code-authenticator',
  templateUrl: './recovery-code-authenticator.component.html',
  styleUrls: ['./recovery-code-authenticator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatInputModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule],
})
export class MFARecoveryCodeAuthenticatorComponent implements AfterViewInit, OnDestroy {
  constructor(private msg: MessageService, private auth: AuthService) {}

  @Output() done = new EventEmitter<boolean>();

  @ViewChild('input')
  readonly input!: MatInput;

  @ViewChild('input', { read: ElementRef<HTMLInputElement> })
  readonly inputElement!: ElementRef<HTMLInputElement>;

  readonly processing = signal(false);

  private _destroyed = false;

  async ngAfterViewInit() {
    let focused = false;
    let tries = 0;
    let error;

    while (!this._destroyed && !focused && tries < 25) {
      //? Retry delay + initial delay to override other focus
      await new Promise((res) => setTimeout(res, 200));

      try {
        this.inputElement.nativeElement.focus();
        focused = true;
      } catch (e) {
        error = e;
      }

      tries++;
    }

    if (!focused && error) console.warn(`[${MFARecoveryCodeAuthenticatorComponent}]`, 'Digit focus failed:', error);
  }

  ngOnDestroy(): void {
    this._destroyed = true;
  }

  private async _codeEntered(code: string) {
    await this.auth.mfa({
      type: 'recovery/code',
      code,
    });
  }

  async codeEntered(code: string) {
    try {
      this.processing.set(true);
      await this._codeEntered(code);
      this.done.emit(true);
    } catch (error) {
      this.msg.showErrorSnackbar('Napaka pri preverjanju MFA kode', error);

      this.input.value = '';
    } finally {
      this.processing.set(false);
    }
  }
}
