<form class="form-container" [formGroup]="searchForm">
  <mat-form-field class="rcg-m-width-whole">
    <input matInput [placeholder]="search_CAPTION" formControlName="search" />

    <mat-icon
      matSuffix
      [matBadge]="count | metricNumber : 1 : true"
      [class.infinity-badge]="(count ?? 0) >= 1000000000000000000000000000000"
      [matTooltip]="(count | number : '1.0-0' : 'sl-SI') ?? ''"
      matTooltipPosition="above"
      [matTooltipDisabled]="(count ?? 0) < 1000"
      class="field-icon-pointer"
      (click)="isSearching ? clearSearchClicked() : searchClicked(searchForm!.get('search')!.value)"
    >
      {{ isSearching ? 'clear' : 'search' }}
    </mat-icon>
  </mat-form-field>
</form>
