<div style="display: flex; justify-content: center; align-items: center; gap: 4px">
  <rcg-otp-pin-input
    #pinInput
    style="width: calc(100% - 92px)"
    [numDigits]="numDigits"
    [disabled]="processing"
    (codeEntered)="codeEntered($event)"
  ></rcg-otp-pin-input>

  <button
    *ngVar="allowResendInSeconds() as arIs"
    mat-icon-button
    color="primary"
    [matTooltip]="'mfa_sms_send_code' | intl"
    [matBadge]="arIs ? arIs : ''"
    [disabled]="!okOtpCodes() || !!arIs"
    (click)="requestCode()"
  >
    <mat-icon>send</mat-icon>
  </button>
</div>

<mat-spinner *ngIf="processing()" mode="indeterminate" [diameter]="48"></mat-spinner>
