<ng-template #loading>
  <div class="loading-container">
    <mat-spinner mode="indeterminate"></mat-spinner>
    <mat-icon>account_box</mat-icon>
  </div>
</ng-template>

@if (done()) {
<rcg-auth-guard [uiOnly]="true">
  <ng-container *ngTemplateOutlet="loading"></ng-container>
</rcg-auth-guard>
} @else {
<ng-container *ngTemplateOutlet="loading"></ng-container>
}
