@if(!loggedIn()) {
<mat-toolbar color="primary" [style.display]="hideToolbar() ? 'none' : ''">
  <span>RcG Assist</span>

  <button mat-icon-button *ngIf="canLogOut()" (click)="logout()" [matTooltip]="'log_out' | intl">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>
}

<ng-template #content>
  @if (contentTemplate) {
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  } @else {
  <ng-content></ng-content>
  }
</ng-template>

@if (passthrough()) {
<ng-container *ngTemplateOutlet="content"></ng-container>
} @else {
<!-- -->
@if (error(); as error) {
<div class="error-container">
  <h2>{{ 'error' | intl }}</h2>
  <pre>{{ error.error }}</pre>
</div>
} @else if (loading()) {
<div class="loading-container">
  <mat-spinner mode="indeterminate"></mat-spinner>
  <mat-icon>person</mat-icon>
</div>
} @else if (loggedOut()) {
<rcg-login></rcg-login>
} @else if (mfa()) {
<rcg-mfa></rcg-mfa>
} @else if (!tenant()) {
<rcg-tenant></rcg-tenant>
} @else {
<ng-container *ngTemplateOutlet="content"></ng-container>
}
<!-- -->
}

<!-- DEBUG -->
@if (debugEnabled()) {
<div class="debug-container">
  <div class="debug-actions">
    <a (click)="disableDebug()">X</a>
    <span>&nbsp;&nbsp;&nbsp;</span>
    <a (click)="hideDebugFor5Seconds()">H</a>
  </div>

  <h2 style="text-align: center; flex-grow: 1">AUTH DEBUG</h2>

  <div class="content">
    <table>
      <tr>
        <th>State:</th>
        <td>
          <pre>{{ stateName() }}</pre>
        </td>
      </tr>

      <tr>
        <th>User:</th>
        <td>
          @if (user(); as user) {
          <table>
            <tr>
              <td class="r-align">ID:</td>
              <td>{{ user.id }}</td>
            </tr>
            <tr>
              <td class="r-align">Name:</td>
              <td>{{ user.fullName }}</td>
            </tr>
            <tr>
              <td class="r-align">Email:</td>
              <td>{{ user.email }}</td>
            </tr>
          </table>
          } @else {
          <pre>null</pre>
          }
        </td>
      </tr>

      <tr>
        <th>Picked tenant:</th>
        @if (pickedTenant(); as pt) {
        <td>#{{ pt }}</td>
        } @else {
        <td>null</td>
        }
      </tr>

      <tr>
        <th>Tenant:</th>
        @if (tenant(); as tenant) {
        <td>#{{ tenant.id }} - {{ tenant.description }}</td>
        } @else {
        <td><pre>null</pre></td>
        }
      </tr>

      <tr>
        <th>User tenants:</th>

        <td>
          <table>
            @for (tenant of user()?.tenants; track $index) {
            <tr>
              <td class="r-align">#{{ tenant.id }}:</td>
              <td>{{ tenant.description }}</td>
            </tr>
            }
          </table>
        </td>
      </tr>

      <tr>
        <th>Authenticatable MFA factors:</th>

        <td>
          <table>
            @for (mf of authenticatableMfaFactors(); track $index) {
            <tr>
              <td>
                <pre>{{ mf.type }}</pre>
              </td>
            </tr>
            } @if (!authenticatableMfaFactors()?.length) {
            <tr>
              <td><pre>[]</pre></td>
            </tr>
            }
          </table>
        </td>
      </tr>

      <tr>
        <th>Present MFA factors:</th>

        <td>
          <table>
            @for (mf of presentMfaFactors(); track $index) {
            <tr>
              <td>
                <pre>{{ mf.type }}</pre>
              </td>
            </tr>
            } @if (!presentMfaFactors()?.length) {
            <tr>
              <td><pre>[]</pre></td>
            </tr>
            }
          </table>
        </td>
      </tr>
    </table>
  </div>
</div>
}
