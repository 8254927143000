import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { RcgDirectivesModule } from '../../directives';
import { fullscreenDialog, minimizeDialog, removeMinimizedDialog } from '../../utils/dialog-utils';
import { resizable } from '../../utils/resizable';

type RcgDialogtoolbarAction = 'recenter' | 'minimize' | 'fullscreen' | 'close';

@Component({
  selector: 'rcg-dialog[dialogRef][icon][title]',
  templateUrl: './rcg-dialog.component.html',
  styleUrls: ['./rcg-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatIconModule, MatToolbarModule, RcgDirectivesModule, IntlModule, MatTooltipModule],
})
export class RcgDialogComponent<T> implements OnInit, AfterViewInit {
  readonly minimizeDialog = minimizeDialog;
  readonly fullscreenDialog = fullscreenDialog;

  @Input() dialogRef!: MatDialogRef<T>;

  @Input() icon!: string;

  @Input() title!: string;

  @Input() iconTooltip?: string;

  @Input() disabledToolbarActions: RcgDialogtoolbarAction[] = [];

  @Input() disableResize = false;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.dialogRef.beforeClosed().subscribe(() => {
      const pane = this.elementRef.nativeElement.closest<HTMLElement>('.cdk-overlay-pane')!;

      try {
        removeMinimizedDialog(pane);
      } catch (error) {
        console.warn('Failed to remove minimized dialog:', error);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.disableResize) return;

    const paneEl = this.elementRef.nativeElement.closest<HTMLElement>('.cdk-overlay-pane');

    if (!paneEl) {
      console.warn('Cannot init dialog resize:', "Couldn't find overlay pane element!");
      return;
    }

    resizable(paneEl);
  }
}
