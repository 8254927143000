import { Apollo } from 'apollo-angular';
import { AuthStateWithUser, RcgUser } from '..';
import { AuthState, AuthStateFlags } from './base';

export class PickingTenantAuthState extends AuthState implements AuthStateWithUser {
  override readonly flags = AuthStateFlags.canChangeTenant | AuthStateFlags.canLogOut;
  override readonly __tg_isAuthStateWithUser = true;

  constructor(apollo: Apollo, public readonly user: RcgUser) {
    super(apollo);
  }
}
