import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { AfterViewInit, Directive, ElementRef, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';

const dragStatusChangeProp = '__rcg_dragStatusChange';

type DSCPane = { [dragStatusChangeProp]?: (() => void)[] } | null;

@Directive({
  selector: '[rcgDialogDragHandle]',
  hostDirectives: [CdkDrag, CdkDragHandle],
})
export class DialogDragHandleDirective implements OnInit, AfterViewInit, OnDestroy {
  private readonly cdkDrag = inject(CdkDrag, { self: true });

  private static wrapperZIndex = 10000;

  private dragSub?: Subscription;
  private isDragged = false;

  constructor(private hostElement: ElementRef) {}

  ngOnInit(): void {
    this.cdkDrag.rootElementSelector = '.cdk-overlay-pane';
    //this.cdkDrag.boundaryElement = '.cdk-overlay-container';

    this.cdkDrag.constrainPosition = (
      { x: userX, y: userY },
      _dragRef,
      { width: dialogWidth, height: dialogHeight },
      { x: pickupX, y: pickupY },
    ) => {
      const hostEl = this.hostElement.nativeElement as HTMLElement | undefined;
      const pane = hostEl?.closest<HTMLElement>('.cdk-overlay-pane');
      const paneRect = pane?.getBoundingClientRect();

      if (pane?.classList.contains('rcg-fullscreen-cdk-overlay-pane')) return { x: paneRect!.x, y: paneRect!.y };

      let x = userX - pickupX;
      let y = userY - pickupY;

      const { width: bodyWidth, height: bodyHeight } = document.body.getBoundingClientRect();

      const minX = 64 - dialogWidth;
      const minY = document.querySelector('mat-toolbar')?.getBoundingClientRect().height ?? 0;

      const maxX = bodyWidth + (dialogWidth - 64);
      const maxY = bodyHeight + (dialogHeight - (hostEl?.getBoundingClientRect().height ?? 64));

      if (x < minX) x = minX;
      if (y < minY) y = minY;

      if (x + dialogWidth > maxX) x = maxX - dialogWidth;
      if (y + dialogHeight > maxY) y = maxY - dialogHeight;

      return { x, y };
    };
  }

  ngAfterViewInit(): void {
    const hostEl = this.hostElement.nativeElement as HTMLElement;
    const wrapper = hostEl.closest<HTMLElement>('.cdk-global-overlay-wrapper');
    const pane = hostEl.closest<HTMLElement>('.cdk-overlay-pane');

    if (!wrapper || !pane) {
      console.warn('Dialog wrapper or pane not found! Drag & drop bring to front will not work properly!');
    }

    const recenterIcon = pane?.querySelector<HTMLElement>('.rcg-dialog-recenter-icon');

    if (recenterIcon) {
      recenterIcon.onclick = () => {
        this.cdkDrag.reset();
        this.isDragged = false;

        recenterIcon.classList.remove('rcg-dialog-recenter-icon-show');
        pane?.classList.remove('rcg-dialog-dragged');

        const dragStatusChange = (pane as DSCPane)?.[dragStatusChangeProp];
        if (Array.isArray(dragStatusChange)) {
          for (const fn of dragStatusChange) {
            try {
              fn();
            } catch (e) {
              console.warn('Dialog dragStatusChange exception:', e);
            }
          }
        }
      };
    }

    const bringToFront = () => {
      if (wrapper) wrapper.style.zIndex = `${DialogDragHandleDirective.wrapperZIndex++}`;
    };

    setTimeout(() => bringToFront());

    this.dragSub = this.cdkDrag.started.subscribe(() => {
      bringToFront();

      if (this.isDragged) return;
      this.isDragged = true;

      if (recenterIcon) {
        recenterIcon.classList.add('rcg-dialog-recenter-icon-show');
      }

      if (!pane) return;

      pane.classList.add('rcg-dialog-dragged');

      const dragStatusChange = (pane as DSCPane)?.[dragStatusChangeProp];

      if (Array.isArray(dragStatusChange)) {
        for (const fn of dragStatusChange) {
          try {
            fn();
          } catch (e) {
            console.warn('Dialog dragStatusChange exception:', e);
          }
        }
      }
    });

    pane?.addEventListener('click', () => bringToFront());
  }

  ngOnDestroy(): void {
    this.dragSub?.unsubscribe();
  }
}
