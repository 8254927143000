import { Apollo } from 'apollo-angular';
import { AuthState, AuthStateFlags, AuthStateWithImpersonator, AuthStateWithUser, RcgUser } from '../..';

export class ImpersonatingPickingTenantAuthState extends AuthState implements AuthStateWithUser, AuthStateWithImpersonator {
  override readonly flags = AuthStateFlags.canChangeTenant | AuthStateFlags.canLogOut | AuthStateFlags.isImpersonating;
  override readonly __tg_isAuthStateWithUser = true;
  override readonly __tg_isAuthStateWithImpersonator = true;

  constructor(apollo: Apollo, public readonly user: RcgUser, public readonly impersonator: RcgUser) {
    super(apollo);
  }
}
