import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rcg-status-color',
  templateUrl: './status-color.component.html',
  styleUrls: ['./status-color.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class StatusColorComponent {
  @Input() color: string | null = null;

  styles = new Object();

  get colorHex(): string | null {
    const color = this.color?.trim();
    return color ?? null;
  }
}
