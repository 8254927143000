import { Apollo } from 'apollo-angular';
import { AuthState, AuthStateFlags } from './base';

export class ErrorAuthState extends AuthState {
  override readonly flags = AuthStateFlags.resetsPickedTenant | AuthStateFlags.canLogOut;

  constructor(apollo: Apollo | undefined, public readonly error: unknown) {
    super(apollo);
  }
}
