import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngAfterViewInit]',
})
export class NgAfterViewInitDirective implements AfterViewInit {
  @Output('ngAfterViewInit') initEvent = new EventEmitter<void>();

  ngAfterViewInit() {
    setTimeout(() => this.initEvent.emit(), 10);
  }
}
