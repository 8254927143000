import { Apollo } from 'apollo-angular';
import {
  AuthenticatingAuthState,
  ImpersonatingLoggedInAuthState,
  LoadingAuthState,
  LoggedInAuthState,
  LoggedOutAuthState,
  MfaAuthState,
  RcgTenant,
  RcgUser,
} from '..';

export const enum AuthStateFlags {
  none = 0,
  resetsPickedTenant = 1 << 0,
  isImpersonating = 1 << 1,
  canChangeTenant = 1 << 2,
  canLogOut = 1 << 3,
}

type ExcludeNone<T> = T extends AuthStateFlags.none ? never : T;
type ToNum<T extends string> = { [K in T]: K extends `${infer N extends number}` ? N : never }[T];

export type AuthStateFlag = ToNum<`${ExcludeNone<AuthStateFlags>}`>;

export interface AuthStateWithUser {
  readonly user: RcgUser;
  readonly __tg_isAuthStateWithUser: true;
}

export interface AuthStateWithImpersonator {
  readonly impersonator: RcgUser;
  readonly __tg_isAuthStateWithImpersonator: true;
}

export interface AuthStateWithTenant {
  readonly tenant: RcgTenant;
  readonly __tg_isAuthStateWithTenant: true;
}

export abstract class AuthState {
  abstract readonly flags: AuthStateFlags;

  // Type guards
  protected readonly __tg_isAuthStateWithUser: boolean = false;
  protected readonly __tg_isAuthStateWithImpersonator: boolean = false;
  protected readonly __tg_isAuthStateWithTenant: boolean = false;

  hasUser(): this is AuthStateWithUser {
    return this.__tg_isAuthStateWithUser;
  }

  hasImpersonator(): this is AuthStateWithImpersonator {
    return this.__tg_isAuthStateWithImpersonator;
  }

  hasTenant(): this is AuthStateWithTenant {
    return this.__tg_isAuthStateWithTenant;
  }

  isLoading(): this is LoadingAuthState {
    return this instanceof LoadingAuthState;
  }

  isAuthenticating(): this is AuthenticatingAuthState {
    return this instanceof AuthenticatingAuthState;
  }

  isLoggedOut(): this is LoggedOutAuthState {
    return this instanceof LoggedOutAuthState;
  }

  isMfa(): this is MfaAuthState {
    return this instanceof MfaAuthState;
  }

  isLoggedIn(): this is LoggedInAuthState | ImpersonatingLoggedInAuthState {
    return this instanceof LoggedInAuthState || this instanceof ImpersonatingLoggedInAuthState;
  }

  constructor(public readonly apollo?: Apollo) {}
}
