import { gql } from '@apollo/client/core';

export const userTenantsDetailQuery = gql`
  query userTenantsDetail($userId: bigint!) {
    data: userTenantsDetailQuery(userId: $userId) {
      data
    }
  }
`;

export const hostDefaultTenantQuery = gql`
  query HostDefaultTenantQuery($host: String!) {
    data: identity_angular_host_default_tenant_by_pk(host: $host) {
      tenant_id
    }
  }
`;
