<rcg-dialog [dialogRef]="dialogRef" [icon]="data.icon" [title]="data.title" [disabledToolbarActions]="['minimize', 'fullscreen']">
  <div class="dialog-content">
    <span>{{ data.message }}</span>
  </div>

  <mat-dialog-actions [align]="'end'">
    <button mat-raised-button color="primary" [mat-dialog-close]="false" cdkFocusInitial>
      {{ data.cancelText }}
    </button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">
      {{ data.confirmText }}
    </button>
  </mat-dialog-actions>
</rcg-dialog>
