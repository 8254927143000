import { Apollo } from 'apollo-angular';
import { AuthStateWithTenant, AuthStateWithUser, RcgTenant, RcgUser } from '..';
import { AuthState, AuthStateFlags } from './base';

export class LoggedInAuthState extends AuthState implements AuthStateWithUser, AuthStateWithTenant {
  override readonly flags = AuthStateFlags.resetsPickedTenant | AuthStateFlags.canChangeTenant | AuthStateFlags.canLogOut;
  override readonly __tg_isAuthStateWithUser = true;
  override readonly __tg_isAuthStateWithTenant = true;

  constructor(apollo: Apollo, public readonly user: RcgUser, public readonly tenant: RcgTenant) {
    super(apollo);
  }
}
