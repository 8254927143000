import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { IntlModule } from '@rcg/intl';
import { MessageService } from '@rcg/standalone/services';
import { AuthService } from '../../../auth.service';
import { CenteredCardComponent } from '../../centered-card/centered-card.component';

@Component({
  selector: 'rcg-forgot-password',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule, MatButtonModule, IntlModule, FormlyModule, CenteredCardComponent],
})
export class ForgotPasswordComponent {
  @Output() goBack = new EventEmitter<void>();

  @Input() model: { email: string } = { email: '' };
  @Output() modelChange = new EventEmitter<typeof this.model>();

  constructor(private authService: AuthService, private messageService: MessageService) {}

  form = new UntypedFormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      props: {
        type: 'email',
        label: 'Email ',
        placeholder: 'Vnesi email',
        required: true,
        pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      },
    },
  ];

  async onSubmit(): Promise<void> {
    try {
      if (!this.form.valid || !this.model?.email) {
        this.messageService.showErrorSnackbar('Napaka: E-mail ni veljaven', '', 5);
        return;
      }
      await this.authService.resetPassword(this.model.email);
      this.messageService.showInfoSnackbar(`E-mail za ponastavitev gesla je bil poslan na ${this.model.email}`, '', 'OK', 5);
    } catch (error) {
      this.messageService.showErrorSnackbar(`Napaka pri ponastavljanju gesla:\n${error}`, error, 5);
    }
  }
}
