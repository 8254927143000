import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Params, RouterModule } from '@angular/router';
import { IntlModule } from '@rcg/intl';
import { IPaging } from '../../../models/paging.model';
import { ModuleSearchComponent } from '../../module-search/module-search.component';
import { ListDynamicStyle } from '../dynamic-style.decorator';

@ListDynamicStyle
@Component({
  selector: 'rcg-virtual-list',
  templateUrl: './virtual-list.component.html',
  styleUrls: ['./virtual-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, ScrollingModule, MatProgressBarModule, MatCardModule, ModuleSearchComponent, IntlModule],
})
export class RcgVirtualListComponent {
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  virtualScroll?: CdkVirtualScrollViewport;

  @Input() itemTemplate?: TemplateRef<unknown>;

  @Input() listHeader?: TemplateRef<unknown> | null | undefined;

  @Input() data: unknown[] = [];

  @Input() paging!: IPaging;

  @Input() loading = false;

  @Input() error: string | null | undefined = null;

  @Input() queryParams?: Params;

  @Input() itemSize?: number = 80;

  @Input() itemWidth?: string = '100%';

  @Input() padding?: string;

  @Input() margin?: string;

  @Input() wrapInCard = true;

  @Input() searchable = false;

  @Input() @HostBinding('class.item-margin') itemMargin = true;
  @Input() @HostBinding('class.scale-hover') scaleHover = false;
  @Input() @HostBinding('style.--vl-transform-origin') transformOrigin?: string;
  @Input() @HostBinding('style.--vl-hover-scale') hoverScale: number = 1.1;
  @Input() @HostBinding('style.--vl-hover-trailing-right-margin') hoverTrailingRightMargin?: string;

  @Input() route?: string;

  @Input() trackBy?: TrackByFunction<unknown>;

  @Output() loadMore = new EventEmitter<IPaging>();

  @Output() search = new EventEmitter<string>();

  constructor(public hostElRef: ElementRef) {}

  scrollIndexChanged(index: number, paging: IPaging, loading: boolean): void {
    if (!this.virtualScroll || !this.paging) {
      return;
    }
    if (index === 0 && loading === false && this.data.length > this.paging.pageLimit) {
      const loadFirstPage: IPaging = {
        ...paging,
        limit: this.paging.pageLimit,
        hasMore: true,
      };
      this.loadMore.emit(loadFirstPage);
      return;
    }

    if (!this.paging.hasMore) {
      return;
    }

    const renderedRange: ListRange = this.virtualScroll.getRenderedRange();
    const totalItems: number = this.virtualScroll.getDataLength();
    const lastRenderedItem: number = renderedRange.end;
    // const firstRenderedItem: number = renderedRange.start;

    const canLoadMore = lastRenderedItem === totalItems;

    if (!canLoadMore) {
      return;
    }

    if (loading) {
      return;
    }

    const loadMore: IPaging = {
      ...paging,
      limit: this.data.length + this.paging.pageLimit,
    };
    this.loadMore.emit(loadMore);
  }

  searchChanged(search: string) {
    this.search.emit(search);
  }
}
