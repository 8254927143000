import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  inject,
  Input,
  OnChanges,
  signal,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../auth.service';
import { AuthStateFlags } from '../../models';
import { ForgotPasswordComponent } from '../state/forgot/forgot.component';
import { LoginComponent } from '../state/login/login.component';
import { MfaComponent } from '../state/mfa/mfa.component';
import { TenantComponent } from '../state/tenant/tenant.component';

@Component({
  selector: 'rcg-auth-guard',
  templateUrl: './auth-guard.component.html',
  styleUrls: ['./auth-guard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
    IntlModule,
    LoginComponent,
    ForgotPasswordComponent,
    TenantComponent,
    MfaComponent,
  ],
})
export class AuthGuardComponent implements OnChanges {
  private readonly authService = inject(AuthService);

  @Input() hideToolbar$?: Observable<boolean>;
  private readonly _hideToolbarObs = signal<Observable<boolean> | null | undefined>(null);
  public readonly hideToolbar = toSignal(toObservable(this._hideToolbarObs).pipe(switchMap((o) => o ?? of(false))));

  @Input() passthrough$?: Observable<boolean>;
  private readonly _passthroughObs = signal<Observable<boolean> | null | undefined>(null);
  public readonly passthrough = toSignal(toObservable(this._passthroughObs).pipe(switchMap((o) => o ?? of(false))));

  @Input() uiOnly?: boolean;
  private readonly _uiOnlyS = signal(false);
  public readonly uiOnlyS = this._uiOnlyS.asReadonly();

  @ContentChild(TemplateRef<unknown>) contentTemplate?: TemplateRef<unknown>;

  public readonly error = this.authService.errorAuthState;
  public readonly loading = computed(() => this.authService.authState().isLoading());
  public readonly loggedOut = computed(() => this.authService.authState().isLoggedOut());
  public readonly mfa = computed(() => this.authService.authState().isMfa());

  public readonly user = this.authService.user;
  public readonly pickedTenant = this.authService.pickedTenant;
  public readonly tenant = this.authService.tenant;

  public readonly authenticatableMfaFactors = this.authService.authenticatableMfaFactors;
  public readonly presentMfaFactors = this.authService.presentMfaFactors;

  public readonly loggedIn = this.authService.loggedInAuthState;
  public readonly canLogOut = computed(() => this.authService.authState()?.flags & AuthStateFlags.canLogOut);
  public readonly logout = this.authService.logout.bind(this.authService);

  private readonly _authDebugStorageKey = 'rcgNgAuthDebug';
  public readonly debug = signal(localStorage.getItem(this._authDebugStorageKey));
  public readonly debugEnabled = computed(() => !!this.debug() && !this.uiOnlyS());

  public readonly stateName = computed(() => this.authService.authState().constructor.name);

  constructor() {
    const win = window as unknown as Record<string, unknown>;

    win['rcgNgAuthDebugEnable'] = (what: string) => {
      const w = what ?? 'all';

      localStorage.setItem(this._authDebugStorageKey, w);
      this.debug.set(w);
    };

    win['rcgNgAuthDebugDisable'] = this.disableDebug;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('uiOnly' in changes) {
      const uio = this.uiOnly ?? false;
      this._uiOnlyS.set(uio);

      if (uio) {
        this._hideToolbarObs.set(of(true));
        this._passthroughObs.set(of(false));
        return;
      }
    }

    if ('hideToolbar$' in changes) {
      this._hideToolbarObs.set(this.hideToolbar$);
    }

    if ('passthrough$' in changes) {
      this._passthroughObs.set(this.passthrough$);
    }
  }

  public readonly disableDebug = () => {
    localStorage.removeItem(this._authDebugStorageKey);
    this.debug.set(null);
  };

  hideDebugFor5Seconds() {
    const oldVal = this.debug();
    if (!oldVal) return;

    this.debug.set(null);
    setTimeout(() => {
      if (this.debug()) return;
      this.debug.set(oldVal);
    }, 5000);
  }
}
