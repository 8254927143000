import { A11yModule } from '@angular/cdk/a11y';
import { ObserversModule } from '@angular/cdk/observers';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RcgDialogComponent } from '../rcg-dialog/rcg-dialog.component';

export interface ConfirmDialogData {
  icon: string;
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  width?: string;
  height?: string;
  disableClose?: boolean;
}

@Component({
  selector: 'rcg-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [A11yModule, ObserversModule, MatDialogModule, MatButtonModule, RcgDialogComponent],
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData, public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
}
