import { computed, effect, inject, Injectable, Injector, NgZone, OnDestroy, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { EventType, Router } from '@angular/router';
import { AuthManager, AuthToken, RefreshToken, Token, WebTokenStorage } from '@npm-libs/auth-manager';
import { CreateApolloClientFactory, GRAPHQL_APOLLO_CLIENT_OPTIONS, GraphqlClientService } from '@rcg/graphql';
import { Mutex } from '@rcg/utils';
import { isNonNullable } from '@rcg/utils/type.utils';
import * as Sentry from '@sentry/angular-ivy';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  catchError,
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  of,
  retry,
  switchMap,
} from 'rxjs';
import { authBaseUrl, refreshTokenStorageKey } from './constants';
import { InvalidAuthStateError } from './errors/invalid-state';
import { hostDefaultTenantQuery, userTenantsDetailQuery } from './gql';
import {
  AuthenticatingAuthState,
  AuthState,
  AuthStateFlags,
  ErrorAuthState,
  ImpersonatingLoggedInAuthState,
  ImpersonatingPickingTenantAuthState,
  LoadingAuthState,
  LoggedInAuthState,
  LoggedOutAuthState,
  MfaAuthState,
  PickingTenantAuthState,
  RcgTenant,
  RcgUser,
} from './models';
import { Hasura } from './models/hasura';
import { logError } from './utils/log-error';
import { changeAppThemeOrDefault } from './utils/theme';

class _TokenExposedAuthManager extends AuthManager {
  get authServiceTokens() {
    return this._tokens;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private readonly injector = inject(Injector);
  private readonly ngZone = inject(NgZone);
  private readonly httpLink = inject(HttpLink);

  private readonly router = inject(Router);

  private readonly apolloClientFactoryOptions = inject(GRAPHQL_APOLLO_CLIENT_OPTIONS);

  constructor() {
    this._initPeriodicRefresh();
    this._initPickedTenantReset();
    this._initDefaultTenantChange();
    this._initPostMfaTenantPick();
    this._initSentry();
    this._initThemeChange();
  }

  private _initPeriodicRefresh() {
    setInterval(() => this._authManager.getAuthToken(), 300 * 1000);
  }

  private _initPickedTenantReset() {
    effect(
      () => {
        const authState = this.authState();

        if (authState.flags & AuthStateFlags.resetsPickedTenant) this._pickedTenant.set(null);
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  private _initDefaultTenantChange() {
    let canChangeHostDefault = true;

    effect(() => {
      const state = this.authState();

      if (state instanceof LoggedInAuthState) return (canChangeHostDefault = false);
      if (state instanceof LoggedOutAuthState) return (canChangeHostDefault = true);

      return true;
    });

    let currentQpTenant: number | null;

    effect(
      () => {
        const state = this.authState();
        if (!(state.flags & AuthStateFlags.canChangeTenant)) return;

        const qpTenantId = this.queryParamTenant();

        if (qpTenantId && qpTenantId !== currentQpTenant) {
          currentQpTenant = qpTenantId;
          this.changeTenant(qpTenantId).catch((error) => console.error('Failed to change tenant to the query param tenant:', error));

          (async () => {
            if (this.router.getCurrentNavigation()) {
              await Promise.race([
                firstValueFrom(this.router.events.pipe(filter((e) => e.type === EventType.NavigationEnd))),
                new Promise((res) => setTimeout(res, 1000)),
              ]);
            }

            await this.router.navigate([window.location.pathname], {
              queryParams: {
                tenant: null,
              },
              queryParamsHandling: 'merge',
            });
          })();

          return;
        }

        if (!qpTenantId) currentQpTenant = null;

        if (!(state instanceof PickingTenantAuthState)) return;
        if (!canChangeHostDefault) return;

        const hdTenantId = this.hostDefaultTenant();
        if (!hdTenantId) return;

        this.changeTenant(hdTenantId).catch((error) => console.error('Failed to change tenant to the host default tenant:', error));
        canChangeHostDefault = false;
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  private _initPostMfaTenantPick() {
    effect(
      () => {
        const state = this.authState();
        if (!(state instanceof MfaAuthState)) return;

        const pickedTenant = this._pickedTenant();
        if (!pickedTenant) return;

        const presentMfaFactors = this.presentMfaFactors();
        if (!presentMfaFactors?.length) return;

        this.changeTenant(pickedTenant);
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  private _initSentry() {
    effect(() => {
      const authState = this.authState();

      if (!authState.hasUser()) {
        Sentry.setUser(null);
        Sentry.setExtra('impersonator', undefined);
        return;
      }

      const user = authState.user;

      const sentryUser: Sentry.User = {
        id: user.id.toString(),
        email: user.email,
        ip_address: '{{auto}}',
      };

      Sentry.setUser(sentryUser);
      Sentry.setExtra('impersonator', authState.hasImpersonator() ? authState.impersonator : undefined);
    });
  }

  private _initThemeChange() {
    effect(() => {
      const tenant = this.tenant();

      const theme = tenant?.theme.split('/');
      changeAppThemeOrDefault(theme?.[0], theme?.[1]);
    });
  }

  ngOnDestroy(): void {
    this._authManager.destroy();
  }

  private readonly _authManager = new _TokenExposedAuthManager(
    authBaseUrl,
    'user',
    new WebTokenStorage(localStorage, RefreshToken.deserialize, refreshTokenStorageKey),
    logError,
  );

  private _authenticating = signal(false);
  private _authMutex = new Mutex({ onLockChanged: (l) => this._authenticating.set(l) });

  private _pickedTenant = signal<number | null>(null);
  public readonly pickedTenant = this._pickedTenant.asReadonly();

  private readonly _hdtApollo$ = of(null).pipe(
    delay(1),
    map(() => this.injector.get(Apollo)),
  );

  private readonly queryParamTenant = toSignal(
    this.router.events.pipe(
      map(() => window.location.search),
      distinctUntilChanged(),
      map((search) => {
        const sp = new URLSearchParams(search);
        const t = +(sp.get('tenant') ?? 0);
        return t ? t : null;
      }),
      distinctUntilChanged(),
    ),
  );

  private readonly hostDefaultTenant = toSignal(
    GraphqlClientService.queryWithApollo<{ data?: { tenant_id?: number } }>(this._hdtApollo$, {
      query: hostDefaultTenantQuery,
      variables: {
        host: window.location.host,
      },
    }).pipe(
      map((response) => response?.data?.tenant_id),
      retry({ count: 10, delay: 500 }),
    ),
  );

  public readonly authState = toSignal<AuthState, AuthState>(
    combineLatest([toObservable(this._authenticating), toObservable(this._pickedTenant), this._authManager.authServiceTokens]).pipe(
      debounceTime(10),
      map(([authenticating, pickedTenant, { token, refreshToken, impersonator }]) => ({
        authenticating,
        pickedTenant,
        token,
        refreshToken,
        impersonator,
      })),
      switchMap(async ({ authenticating, pickedTenant, token, refreshToken, impersonator }) => {
        if (authenticating) return new AuthenticatingAuthState(undefined);

        if (!token) {
          //? Initial refresh
          if (refreshToken) return new LoadingAuthState(undefined);

          return new LoggedOutAuthState(undefined);
        }

        const blockApolloToken = token?.type !== 'auth';
        const apollo = this._getApollo(async () => (blockApolloToken ? undefined : token.rawToken));

        const userDetail = await this._getUserDetail(apollo, token);
        const { user, tenant } = this._parseUserDetail(token, userDetail, impersonator?.token?.type === 'tenantPick');

        if (impersonator) {
          if (!impersonator.token) return new LoadingAuthState(apollo);

          const impersonatorApollo = this._getApollo(() => impersonator.token!.rawToken);
          const impersonatorDetail = await this._getUserDetail(impersonatorApollo, impersonator.token);
          const { user: impersonatorUser } = this._parseUserDetail(impersonator.token, impersonatorDetail, true);

          switch (token.type) {
            case 'tenantPick':
              return new ImpersonatingPickingTenantAuthState(apollo, user, impersonatorUser);
            case 'auth':
              if (!tenant) throw new Error('[AuthService.authState/impersonator] Missing tenant detail');
              return new ImpersonatingLoggedInAuthState(apollo, user, impersonatorUser, tenant);
            default:
              throw new Error('Unknown token type: impersonator/' + token.type);
          }
        }

        switch (token.type) {
          case 'tenantPick':
            return pickedTenant ? new MfaAuthState(apollo, user) : new PickingTenantAuthState(apollo, user);
          case 'auth':
            if (!tenant) throw new Error('[AuthService.authState] Missing tenant detail');
            return new LoggedInAuthState(apollo, user, tenant);
          default:
            throw new Error('Unknown token type: ' + token.type);
        }
      }),
      catchError((err) => {
        console.error('Auth error', err);
        return of(new ErrorAuthState(undefined, err));
      }),
    ),
    {
      initialValue: new LoadingAuthState(undefined),
    },
  );

  public readonly authState$ = toObservable(this.authState);

  public readonly errorAuthState = computed(() => {
    const as = this.authState();
    return as instanceof ErrorAuthState ? as : null;
  });

  public readonly loggedInAuthState = computed(() => {
    const as = this.authState();
    return as.isLoggedIn() ? as : null;
  });

  public readonly loggedInAuthState$ = toObservable(this.loggedInAuthState);

  public readonly authenticatableMfaFactors = toSignal(
    this._authManager.authServiceTokens.pipe(map(({ token }) => token?.authenticatableMfaFactors ?? [])),
  );

  public readonly presentMfaFactors = toSignal(
    this._authManager.authServiceTokens.pipe(map(({ token }) => token?.presentMfaFactors ?? [])),
  );

  public readonly authInfo = computed(() => {
    const as = this.authState();

    return {
      user: as.hasUser() ? as.user : null,
      tenant: as.hasTenant() ? as.tenant : null,
      impersonator: as.hasImpersonator() ? as.impersonator : null,
    };
  });

  public readonly user = computed(() => this.authInfo().user);
  public readonly tenant = computed(() => this.authInfo().tenant);
  public readonly impersonator = computed(() => this.authInfo().impersonator);

  /** @deprecated Use the {@link authInfo} signal instead */
  public readonly authInfo$ = toObservable(this.authInfo);

  /** @deprecated Use the {@link user} signal instead */
  public readonly user$ = toObservable(this.user);

  /** @deprecated Use the {@link tenant} signal instead */
  public readonly tenant$ = toObservable(this.tenant);

  /** @deprecated Use the {@link impersonator} signal instead */
  public readonly impersonator$ = toObservable(this.impersonator);

  public readonly userChanges = toSignal(toObservable(this.user).pipe(distinctUntilChanged((p, c) => p?.id === c?.id)));

  readonly getAuthToken = this._authManager.getAuthToken.bind(this._authManager);

  public async getRawAuthToken(): Promise<string | undefined> {
    const token = await this.getAuthToken();
    if (token?.type !== 'auth') return undefined;
    return token?.rawToken;
  }

  public readonly isSSO = toSignal(
    this._authManager.authServiceTokens.pipe(
      map(({ refreshToken }) => !!(refreshToken?.payload as { sso_refresh?: unknown } | undefined)?.sso_refresh),
    ),
  );

  /** @deprecated Use the {@link isSSO} signal instead */
  public readonly isSSO$ = toObservable(this.isSSO);

  private _protectedManagerFn<
    FnName extends keyof {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [P in keyof AuthManager as AuthManager[P] extends (...params: any[]) => Promise<unknown> ? P : never]: unknown;
    },
  >(fnName: FnName, check?: () => void) {
    return ((...params: Parameters<AuthManager[FnName]>) => {
      check?.();
      // @ts-expect-error: TS does not correctly infer the types because of generics, the correct type is asserted below with "as".
      return this._authMutex.protect(() => this._authManager[fnName](...params));
    }) as AuthManager[FnName];
  }

  readonly loginWithRefreshToken = this._protectedManagerFn('loginWithRefreshToken');

  readonly login = this._protectedManagerFn('login', () => InvalidAuthStateError.check(this.authState(), LoggedOutAuthState));
  readonly logout = this._protectedManagerFn('logout', () =>
    InvalidAuthStateError.checkFlagSet(this.authState(), AuthStateFlags.canLogOut),
  );

  private async _changeTenant(tenantId: number | undefined) {
    try {
      return await this._authManager.changeTenant(tenantId);
    } catch (error) {
      if (!tenantId) throw error;
      if (!`${error}`.includes('Unfulfilled MFA requirements')) throw error;

      this._pickedTenant.set(tenantId);
    }
  }

  public changeTenant(tenantId: number | undefined) {
    InvalidAuthStateError.checkFlagSet(this.authState(), AuthStateFlags.canChangeTenant);
    return this._authMutex.protect(() => this._changeTenant(tenantId));
  }

  readonly mfa = this._protectedManagerFn('mfa');
  readonly mfaEnroll = this._protectedManagerFn('mfaEnroll');
  readonly initMfaEnroll = this._protectedManagerFn('initMfaEnroll');
  readonly mfaRemove = this._protectedManagerFn('mfaRemove');
  readonly mfaRequestOtpCode = this._authManager.mfaRequestOtpCode.bind(this._authManager);

  readonly resetPassword = this._authManager.resetPassword.bind(this._authManager);
  readonly changePassword = this._authManager.changePassword.bind(this._authManager);

  readonly impersonate = this._protectedManagerFn('impersonate', () =>
    InvalidAuthStateError.checkFlagNotSet(this.authState(), AuthStateFlags.isImpersonating),
  );
  readonly stopImpersonating = this._protectedManagerFn('stopImpersonating', () =>
    InvalidAuthStateError.checkFlagSet(this.authState(), AuthStateFlags.isImpersonating),
  );

  readonly invite = this._authManager.invite.bind(this._authManager);

  private _getApollo(getToken: () => Promise<string | undefined> | string | undefined) {
    const clientOptions = CreateApolloClientFactory(this.httpLink, this.apolloClientFactoryOptions, getToken);

    return new Apollo(this.ngZone, clientOptions);
  }

  private async _getUserDetail(apollo: Apollo, token: Token): Promise<Hasura.UserDetail> {
    if (token.type === 'tenantPick' && 'user_tenants' in token.payload) {
      return {
        user: {
          id: +token.payload.sub!,
          user_name: 'tenantPick',
        },
        tenants: (token.payload.user_tenants as Record<string, unknown>[]).map((d) => ({
          id: d['id'] as number,
          description: d['description'] as string,
          organization: {
            id: d['orgId'] as number,
            name: d['orgName'] as string,
          },
          organizations_share_type: 0,
        })),
      };
    }

    const detail = (
      await firstValueFrom(
        GraphqlClientService.queryWithApollo<{
          data?: { data?: Hasura.UserDetail | null };
        }>(of(apollo), {
          query: userTenantsDetailQuery,
          variables: { userId: token.payload.sub },
        }),
      )
    )?.data?.data;

    if (!detail) throw new Error('[AuthService.getUserDetail] Data is null');
    return detail;
  }

  private _parseUserDetail(token: AuthToken, userDetail: Hasura.UserDetail, skipTenant: true): { user: RcgUser };
  private _parseUserDetail(token: AuthToken, userDetail: Hasura.UserDetail): { user: RcgUser; tenant?: RcgTenant };
  private _parseUserDetail(token: AuthToken, userDetail: Hasura.UserDetail, skipTenant: boolean): { user: RcgUser; tenant?: RcgTenant };
  private _parseUserDetail(token: AuthToken, userDetail: Hasura.UserDetail, skipTenant = false): { user: RcgUser; tenant?: RcgTenant } {
    const user: RcgUser = {
      id: +(token.hasuraClaims?.userId ?? token.payload.sub!),
      email: userDetail.user.user_name,
      firstName: userDetail.user.first_name ?? '',
      lastName: userDetail.user.last_name ?? '',
      fullName: `${userDetail.user.first_name ?? ''} ${userDetail.user.last_name ?? ''}`,
      tenants: userDetail.tenants,
      lastSelectedTenantId: userDetail.user.selected_tenant_id,
      isSuperAdmin: userDetail.user.is_super_admin ?? false,
    };

    const tokenTenantId = token?.hasuraClaims?.tenantId;
    if (!tokenTenantId || skipTenant) return { user };

    const tenantData = tokenTenantId ? userDetail.tenants.find((t) => t.id === tokenTenantId) ?? null : null;
    if (!tenantData) throw new Error(`[AuthService.parseUserDetail] Token tenant not found in user detail: ${tokenTenantId}`);

    const tenant: RcgTenant = {
      id: tenantData.id,
      description: tenantData.description ?? '[N/D]',
      organization: {
        id: tenantData.organization.id,
        name: tenantData.organization.name ?? '[N/N]',
      },
      organizationShareType: tenantData.organizations_share_type,
      phoneEnabled: tenantData.phone_enabled || false,
      isAdmin: tenantData.is_admin || false,
      isAgent: tenantData.is_agent || false,
      isEndUser: tenantData.is_end_user || false,
      groups: tenantData.groups ?? [],
      modules: tenantData.modules ?? [],
      userContacts: tenantData.user_contacts ?? [],
      theme: tenantData.theme ?? 'Default',
    };

    //! Backwards compatibility with forms
    const tenantFormsCompat = {
      organizations_share_type: tenant.organizationShareType,
      is_admin: tenant.isAdmin,
      is_agent: tenant.isAgent,
      is_end_user: tenant.isEndUser,
      user_contacts: tenant.userContacts,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { user, tenant: { ...tenant, ...tenantFormsCompat } } as any;
  }

  public async forceRefresh() {
    const refreshToken = await Promise.race([
      firstValueFrom(
        this._authManager.authServiceTokens.pipe(
          map((t) => t.refreshToken),
          filter(isNonNullable),
        ),
      ),
      new Promise<null>((res) => setTimeout(() => res(null), 2000)),
    ]);

    if (!refreshToken) throw new Error('No refresh token available to force refresh.');

    return this.loginWithRefreshToken(refreshToken.rawToken);
  }
}
