const randClassChars = [
  ...new Array(26).fill(undefined).map((_, i) => String.fromCharCode(i + 65)),
  ...new Array(26).fill(undefined).map((_, i) => String.fromCharCode(i + 97)),
];

const randClassCharsLen = randClassChars.length;

export function randomClassName(len = 11) {
  return 'rc-' + new Array(len)
    .fill(undefined)
    .map(() => randClassChars[Math.floor(Math.random() * randClassCharsLen)])
    .join('');
}

const frameHTML = (doc: string) => `
<!DOCTYPE html>
<html>
  <body>
    <iframe id="frame" srcdoc="${doc.replaceAll('"', '&quot;')}" style="visibility: hidden; height: 0;" sandbox="allow-same-origin">
    </iframe>
  </body>
</html>
`;

export class FrameDOMParser {
  private parser = new DOMParser();

  async parseFromString(string: string, type: 'text/html' = 'text/html'): Promise<Document> {
    if (type != 'text/html') throw new Error('FrameDOMParser only supports text/html parsing');

    const sandboxFrame = this.parser
      .parseFromString(
        frameHTML(string),
        'text/html'
      )
      .getElementById('frame')! as HTMLIFrameElement;

    document.body.append(sandboxFrame);
    await new Promise((res) => sandboxFrame.onload = res);
  
    const sandboxDocument = sandboxFrame.contentWindow!.document;

    setTimeout(
      () => sandboxFrame.remove(),
      1000,
    );

    return sandboxDocument;
  }
}
