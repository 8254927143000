import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

const fullscreenClass = 'rcg-fullscreen-cdk-overlay-pane';
const minimizedClass = 'rcg-minimized-cdk-overlay-pane';

export const minimizedDialogs$ = new BehaviorSubject<[string, string, HTMLElement][]>([]);

export function fullscreenDialog(target: EventTarget | MatDialog | null) {
  if (!(target instanceof HTMLElement)) return;

  const pane = target.closest('.cdk-overlay-pane');
  if (!pane) return;

  if (pane.classList.contains(fullscreenClass)) {
    pane.classList.remove(fullscreenClass);
  } else {
    pane.classList.add(fullscreenClass);
  }
}

export function minimizeDialog(target: EventTarget | null) {
  if (!(target instanceof HTMLElement)) return;

  const pane = target.closest<HTMLElement>('.cdk-overlay-pane');
  if (!pane) return;

  pane.classList.add(minimizedClass);
  minimizedDialogs$.next([
    ...minimizedDialogs$.value,
    [
      pane.querySelector('.rcg-dialog-minimized-icon')?.outerHTML ?? 'Dialog',
      pane.querySelector<HTMLElement>('.rcg-dialog-minimized-icon-tooltip')?.innerText ?? '',
      pane,
    ],
  ]);
}

function removeDialogByIndex(index: number) {
  const md = minimizedDialogs$.value.slice(0);
  const removed = md.splice(index, 1)[0];
  minimizedDialogs$.next(md);

  return removed;
}

export function restoreDialog(index: number) {
  const [, , pane] = removeDialogByIndex(index);

  pane.classList.remove(minimizedClass);
}

export function removeMinimizedDialog(pane: HTMLElement) {
  const index = minimizedDialogs$.value.findIndex(([, , p]) => p === pane);
  if (index === -1) return;
  removeDialogByIndex(index);
}
