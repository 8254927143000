import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rcg-form-title',
  templateUrl: './form-title.component.html',
  styleUrls: ['./form-title.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class FormTitleComponent {
  @Input() title = '';
}
