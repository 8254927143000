import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntlModule } from '@rcg/intl';
import { RcgDialogComponent } from '../rcg-dialog/rcg-dialog.component';
import { DynamicDialogService } from './dynamic-dialog-service';
import { DynamicComponentName } from './models/component.resolver';
import { DynamicDialogInput } from './models/dialog-input';

@Component({
  selector: 'rcg-dynamic-dialog',
  standalone: true,
  imports: [CommonModule, RcgDialogComponent, IntlModule],
  templateUrl: './dynamic-dialog.component.html',
  styleUrl: './dynamic-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;

  componentRef?: ComponentRef<unknown> | undefined;

  constructor(
    public dialogRef: MatDialogRef<DynamicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DynamicDialogInput | null,
    private dialogService: DynamicDialogService,
  ) {}

  async ngAfterViewInit() {
    await this.createComponent();
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
    this.container?.clear();
  }

  async createComponent() {
    if (!this.container) throw new Error('Dynamic component container not found');
    if (!this.dialogData?.component) throw new Error('no dialog data input');

    try {
      this.container.clear();
      if (typeof this.dialogData.component === 'string') {
        const cmpType = await this.dialogService.getComponent(this.dialogData.component as DynamicComponentName);
        this.componentRef = this.container.createComponent(cmpType);
      } else {
        this.componentRef = this.container.createComponent(this.dialogData.component);
      }

      if (!this.componentRef) throw new Error('Component not created. Component ref is null');

      if (this.dialogData?.['inputs']) {
        Object.entries(this.dialogData['inputs']).forEach(([key, value]) => {
          this.componentRef!.setInput(key, value);
        });
      }
      this.componentRef.changeDetectorRef.detectChanges();
    } catch (error) {
      console.error('Error creating component', error);
    }
  }
}
