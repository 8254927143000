import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface Priority {
  id: number;
  description: string;
  icon: string;
}

@Component({
  selector: 'rcg-priority-indicator',
  templateUrl: './priority-indicator.component.html',
  styleUrls: ['./priority-indicator.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatTooltipModule],
})
export class PriorityIndicatorComponent {
  @Input() priority!: Priority;
}
