@if (appLoadingS()) {
<div class="app-loading-container">
  <mat-spinner mode="indeterminate"></mat-spinner>
  <mat-icon>app_settings_alt</mat-icon>
</div>
} @else if (showForgotS()) {
<rcg-forgot-password
  [model]="{ email: model.email }"
  (modelChange)="model.email = $event.email"
  (goBack)="showForgotS.set(false)"
></rcg-forgot-password>
} @else {
<rcg-centered-card>
  <span ngProjectAs="title">{{ 'log_in' | intl }}</span>

  <div class="form">
    @if(fieldsS(); as fields) {
    <form [formGroup]="form">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="model"
        (modelChange)="model.email = $event?.email; model.password = $event?.password"
      ></formly-form>

      <mat-progress-bar mode="indeterminate" [style.visibility]="loggingInS() ? 'visible' : 'hidden'"></mat-progress-bar>

      <div class="form-button">
        @if (!ssoOnlyS()) {
        <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!this.form.valid || loggingInS()">
          {{ 'log_in' | intl }}
        </button>
        } @if (appS()) {
        <button mat-raised-button color="primary" (click)="sso()" [disabled]="loggingInS()">
          {{ 'log_in_sso' | intl }}
        </button>
        } @if (!ssoOnlyS()) {
        <button mat-button (click)="showForgotS.set(true)" [disabled]="loggingInS()">{{ 'forgot_password' | intl }}</button>
        }
      </div>
    </form>
    }
  </div>
</rcg-centered-card>
}
