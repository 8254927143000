import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  ViewChild,
  ViewChildren,
  computed,
  signal,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule, tr } from '@rcg/intl';
import { OtpPinInputComponent } from '@rcg/standalone/components';
import { RcgDirectivesModule } from '@rcg/standalone/directives';
import { MessageService } from '@rcg/standalone/services';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../../auth.service';

const type = 'OTP/SMS';

@Component({
  selector: 'rcg-mfa-sms-authenticator[numDigits]',
  templateUrl: './sms-authenticator.component.html',
  styleUrls: ['./sms-authenticator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RcgDirectivesModule,
    IntlModule,
    OtpPinInputComponent,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatBadgeModule,
  ],
})
export class MFASmsAuthenticatorComponent implements OnInit, OnDestroy {
  @Input() numDigits!: Signal<number>;
  @Output() done = new EventEmitter<boolean>();

  @ViewChildren('digitField', { read: ElementRef })
  readonly digitFields!: ElementRef<HTMLElement>[];

  @ViewChild('pinInput')
  readonly pinInput!: OtpPinInputComponent;

  readonly processing = signal(false);
  readonly okOtpCodes = signal(0);

  private readonly refreshResend = signal(0);
  private readonly lastSentAt = signal(0);

  public readonly allowResendInSeconds = computed(() => {
    this.refreshResend();

    const now = Date.now();

    const period = 60;
    const diff = (now - this.lastSentAt()) / 1000;

    return Math.round(Math.max(0, period - diff));
  });

  private refreshInterval?: number;

  constructor(private msg: MessageService, private auth: AuthService) {}

  ngOnInit(): void {
    this.refreshInterval = window.setInterval(() => this.refreshResend.set(this.refreshResend() + 1), 1000);

    this.requestCode();
  }

  ngOnDestroy(): void {
    window.clearInterval(this.refreshInterval);
  }

  private async _codeEntered(code: string) {
    await this.auth.mfa({
      type,
      code,
    });
  }

  async codeEntered(pin: string) {
    try {
      this.processing.set(true);
      await this._codeEntered(pin);
      this.done.emit(true);
    } catch (error) {
      this.msg.showErrorSnackbar(await firstValueFrom(tr('error')), error);

      this.pinInput.clear();
    } finally {
      this.processing.set(false);
    }
  }

  async requestCode() {
    try {
      this.okOtpCodes.set(0);

      const res = await this.auth.mfaRequestOtpCode(type);

      this.okOtpCodes.set(res.state.filter((s) => s.status === 'ok').length);
      this.lastSentAt.set(Date.now());
    } catch (error) {
      this.msg.showErrorSnackbar(await firstValueFrom(tr('error')), error);
    }
  }
}
