import { AuthState, AuthStateFlag } from '../models';

export class InvalidAuthStateError extends Error {
  private constructor(message: string) {
    super(message);
    this.name = 'InvalidAuthStateError';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static check<S extends AuthState>(authState: AuthState, requiredState: new (...args: any[]) => S) {
    if (authState instanceof requiredState) return;

    throw new InvalidAuthStateError(
      `Required auth state: ${requiredState.prototype.constructor.name}, current state: ${authState.constructor.name}`,
    );
  }

  static checkFlagSet(authState: AuthState, flag: AuthStateFlag) {
    if (authState.flags & flag) return;

    throw new InvalidAuthStateError(`Auth state ${authState.constructor.name} is not allowed (${authState.flags} & ${flag} == 0)`);
  }

  static checkFlagNotSet(authState: AuthState, flag: AuthStateFlag) {
    if (!(authState.flags & flag)) return;

    throw new InvalidAuthStateError(`Auth state ${authState.constructor.name} is not allowed (${authState.flags} & ${flag} != 0)`);
  }
}
