import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, signal, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule } from '@ngx-formly/core';
import { MfaAuthInfo } from '@npm-libs/auth-manager';
import { IntlModule } from '@rcg/intl';
import { RcgDialogComponent } from '@rcg/standalone/components';
import { AuthService } from '../../../auth.service';
import { MFARecoveryCodeAuthenticatorComponent } from '../../authenticators/recovery-code/recovery-code-authenticator.component';
import { MFASmsAuthenticatorComponent } from '../../authenticators/sms/sms-authenticator.component';
import { MFATotpAuthenticatorComponent } from '../../authenticators/totp/totp-authenticator.component';
import { CenteredCardComponent } from '../../centered-card/centered-card.component';

@Component({
  selector: 'rcg-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    IntlModule,
    RcgDialogComponent,
    MFATotpAuthenticatorComponent,
    MFASmsAuthenticatorComponent,
    MFARecoveryCodeAuthenticatorComponent,
    CenteredCardComponent,
  ],
})
export class MfaComponent {
  public readonly addAuthFactorFormFields = [
    {
      type: 'mfaConfigurator',
      props: {
        hiddenButtons: 'modify',
        afterAdded: () => this.afterFactorAdded(),
      },
    },
  ];

  public readonly authenticatableMfaFactors = this.auth.authenticatableMfaFactors;
  public readonly presentMfaFactors = this.auth.presentMfaFactors;

  private readonly _authInfo = signal<MfaAuthInfo | null>(null);
  public readonly authInfo: Signal<MfaAuthInfo | null> = this._authInfo;

  //FIXME: use SignalInput when Angular is updated and it is available
  public readonly numDigits = computed(() => {
    const authInfo = this._authInfo();

    if (!authInfo) return 6;
    if (!('digits' in authInfo)) return 6;

    return authInfo.digits;
  });

  constructor(private auth: AuthService) {
    effect(
      () => {
        const amf = this.authenticatableMfaFactors() ?? [];

        if (amf.length === 1) {
          this.pickFactor(amf[0]);
        } else {
          this.clearFactor();
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  public readonly titleTranslations: Record<string, string | undefined> = {
    'OTP/RFC6238': 'mfa_totp_authenticate_title',
    'OTP/SMS': 'mfa_totp_authenticate_title',
    'recovery/code': 'mfa_recovery_code_authenticate_title',
  };

  public pickFactor(info: MfaAuthInfo) {
    this._authInfo.set(info);
  }

  public clearFactor() {
    this._authInfo.set(null);
  }

  async afterFactorAdded() {
    try {
      await this.auth.forceRefresh();
    } catch (error) {
      console.error('Failed to refresh token after adding MFA factor', error);
      window.location.reload();
    }
  }

  done(status: boolean) {
    console.log('DONE:', status);
  }
}
