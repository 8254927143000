export const knownThemes = ['indigo-blue', 'bluegray-teal', 'green-purple'];
export const knownVariants = ['light', 'dark'];

export function changeAppTheme(name: string, variant: string) {
  if (!knownThemes.includes(name)) {
    throw new Error(`Unknown theme name: ${name}`);
  }

  if (!knownVariants.includes(variant)) {
    throw new Error(`Unknown theme variant: ${variant}`);
  }

  const remove = [...document.body.classList].filter((c) => /^rcg-theme--[a-z-]+--[a-z-]+$/.test(c));

  document.body.classList.remove(...remove);
  document.body.classList.add(`rcg-theme--${name}--${variant}`);
}

export function changeAppThemeOrDefault(name: string | undefined, variant: string | undefined) {
  try {
    if (!name || !variant) throw undefined;
    changeAppTheme(name, variant);
  } catch (error) {
    changeAppTheme(knownThemes[0], knownVariants[0]);
  }
}
