import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, OnDestroy, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '@rcg/standalone/services';
import { AuthService } from '../../auth.service';
import { AuthGuardComponent } from '../auth-guard/auth-guard.component';

@Component({
  selector: 'rcg-query-param-login',
  templateUrl: './query-param-login.component.html',
  styleUrls: ['./query-param-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatProgressSpinnerModule, AuthGuardComponent],
})
export class QueryParamLoginComponent implements OnDestroy {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  private readonly messageService = inject(MessageService);
  private readonly authService = inject(AuthService);

  private readonly queryParamsS = toSignal(this.route.queryParams);

  private readonly _done = signal(false);
  public readonly done = this._done.asReadonly();

  private loadTimeout?: number;

  constructor() {
    this.loadTimeout = window.setTimeout(() => this.goAway(), 3000);

    effect(() => {
      const queryParams = this.queryParamsS();
      if (!queryParams) return;

      const refreshToken = queryParams['refreshToken'] as string | undefined;
      if (!refreshToken) return;

      setTimeout(async () => {
        try {
          await this.authService.loginWithRefreshToken(refreshToken);
          await this.goAway();
        } catch (error) {
          this.messageService.showErrorSnackbar('Napaka pri prijavi.', error, 60);
          console.error('Login error', error);
        }
      });
    });
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.loadTimeout);
  }

  private async goAway() {
    window.clearTimeout(this.loadTimeout);
    this._done.set(true);

    const qp = this.route.snapshot.queryParams;
    const url = new URL(`http://a${qp['path'] ?? '/'}${qp['search'] ?? ''}${qp['hash'] ?? ''}`);

    const rmParams = Object.keys(qp).map((key) => [key, null] as const);
    const params = Object.fromEntries([...rmParams, ...url.searchParams.entries()]);

    await this.router.navigate([url.pathname], {
      queryParams: params,
      queryParamsHandling: 'merge',
      fragment: url.hash.substring(1),
    });
  }
}
