import { Apollo } from 'apollo-angular';
import { AuthState, AuthStateFlags, AuthStateWithImpersonator, AuthStateWithTenant, AuthStateWithUser, RcgTenant, RcgUser } from '../..';

export class ImpersonatingLoggedInAuthState extends AuthState implements AuthStateWithUser, AuthStateWithImpersonator, AuthStateWithTenant {
  override readonly flags =
    AuthStateFlags.resetsPickedTenant | AuthStateFlags.canChangeTenant | AuthStateFlags.canLogOut | AuthStateFlags.isImpersonating;
  override readonly __tg_isAuthStateWithUser = true;
  override readonly __tg_isAuthStateWithImpersonator = true;
  override readonly __tg_isAuthStateWithTenant = true;

  constructor(apollo: Apollo, public readonly user: RcgUser, public readonly impersonator: RcgUser, public readonly tenant: RcgTenant) {
    super(apollo);
  }
}
