import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogDragHandleDirective } from './dialog-drag-handle.directive';
import { NgAfterViewInitDirective } from './ng-after-view-init.directive';
import { NgVarDirective } from './ng-var.directive';

@NgModule({
  declarations: [
    NgAfterViewInitDirective,
    NgVarDirective,
    DialogDragHandleDirective,
  ],
  exports: [
    NgAfterViewInitDirective,
    NgVarDirective,
    DialogDragHandleDirective,
  ],
  imports: [
    CommonModule,
    DragDropModule,
  ],
})
export class RcgDirectivesModule { }
