<rcg-centered-card>
  <span ngProjectAs="title">{{ 'forgot_password' | intl }}</span>

  <div class="form">
    <form [formGroup]="form">
      <formly-form [form]="form" [fields]="fields" [model]="model" (modelChange)="modelChange.emit($event)"></formly-form>

      <div class="form-button">
        <button mat-raised-button color="primary" (click)="onSubmit()">{{ 'reset_password' | intl }}</button>
        @if (goBack) {<button mat-button (click)="goBack.emit()">{{ 'cancel' | intl }}</button>}
      </div>
    </form>
  </div>
</rcg-centered-card>
