interface RangeStartLocation {
  start: number;
}

interface RangeEndLocation {
  end: number;
}

type RangeLocation = RangeStartLocation | RangeEndLocation;

interface Range {
  min: RangeLocation;
  max: RangeLocation;
}

type ResizeDirection = 'n' | 'e' | 's' | 'w' | 'ne' | 'nw' | 'se' | 'sw';

interface PositionProps {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}

function clamp(value: number, min?: number, max?: number) {
  if (min && value < min) return min;
  if (max && value > max) return max;

  return value;
}

function createHandle(element: HTMLElement, xRange: Range, yRange: Range, direction: ResizeDirection) {
  const handleElement = document.createElement('div');

  const props: PositionProps = {};

  if ('start' in xRange.min) props.left = `${xRange.min.start}px`;
  if ('start' in yRange.min) props.top = `${yRange.min.start}px`;

  if ('end' in xRange.min) props.left = `calc(100% - ${xRange.min.end}px)`;
  if ('end' in yRange.min) props.top = `calc(100% - ${yRange.min.end}px)`;

  if ('start' in xRange.max) props.right = `calc(100% - ${xRange.max.start}px)`;
  if ('start' in yRange.max) props.bottom = `calc(100% - ${yRange.max.start}px)`;

  if ('end' in xRange.max) props.right = `${xRange.max.end}px`;
  if ('end' in yRange.max) props.bottom = `${yRange.max.end}px`;

  for (const [k, v] of Object.entries(props) as [keyof PositionProps, string | undefined][]) {
    if (v) handleElement.style[k] = v;
  }

  handleElement.classList.add('rcg-resizable-handle');
  handleElement.style.cursor = `${direction}-resize`;

  const xResize = direction.includes('e') || direction.includes('w');
  const yResize = direction.includes('n') || direction.includes('s');

  const xScale = 2 * (direction.includes('w') ? -1 : 1);
  const yScale = 2 * (direction.includes('n') ? -1 : 1);

  let dragging = false;

  const dragListener = (e: MouseEvent) => {
    if (!dragging) return;

    const bcr = element.getBoundingClientRect();

    const elementCenter = {
      x: () => bcr.left + bcr.width / 2,
      y: () => bcr.top + bcr.height / 2,
    };

    if (xResize) element.style.width = `${clamp((e.clientX - elementCenter.x()) * xScale, 64)}px`;
    if (yResize) element.style.height = `${clamp((e.clientY - elementCenter.y()) * yScale, 64)}px`;
  };

  const mouseUpListener = (e: MouseEvent) => {
    if (e.buttons & 0x1) return;
    dragging = false;
    window.removeEventListener('mousemove', dragListener);
    window.removeEventListener('mouseup', mouseUpListener);
  };

  handleElement.addEventListener('mousedown', (e) => {
    if (!(e.buttons & 0x1)) return;
    e.preventDefault();

    dragging = true;
    window.addEventListener('mousemove', dragListener);
    window.addEventListener('mouseup', mouseUpListener);
  });

  element.appendChild(handleElement);
}

const handleOffset = 6;

const startHandleRange = { min: { start: -handleOffset }, max: { start: handleOffset } };
const endHandleRange = { min: { end: handleOffset }, max: { end: -handleOffset } };

const edgeHandleRange = { min: { start: handleOffset }, max: { end: handleOffset } };

export function resizable(element: HTMLElement) {
  if (!element.style.position || !['relative', 'absolute'].includes(element.style.position)) {
    element.classList.add('rcg-resizable-container-pos');
  }

  createHandle(element, startHandleRange, startHandleRange, 'nw');
  createHandle(element, endHandleRange, startHandleRange, 'ne');
  createHandle(element, startHandleRange, endHandleRange, 'sw');
  createHandle(element, endHandleRange, endHandleRange, 'se');

  createHandle(element, edgeHandleRange, startHandleRange, 'n');
  createHandle(element, endHandleRange, edgeHandleRange, 'e');
  createHandle(element, edgeHandleRange, endHandleRange, 's');
  createHandle(element, startHandleRange, edgeHandleRange, 'w');
}
